// @import "../../../../../scss/variables";
// @import "@coreui/coreui/scss/bootstrap";
// @import "@coreui/coreui/scss/root";

.autoCompleteItemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.autoCompleteItemDescripcion {
  width: 100%;
  font-size: small;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.autoCompleteItemDetails {
  display: flex;
  flex-direction: row;
}

.autoCompleteItemDetail {
  font-weight: lighter;
  margin: 5px;
  font-size: x-small;
}

#textBoxGuiaRemisionPlaca input {
  text-transform: uppercase !important;
}

.guia-placa > div {
  margin-top: 10px;
}

.id-transportista{
  margin-top: 10px;
}

#textBoxGuiaPuntoPartida input {
  text-transform: uppercase !important;
}

#textBoxGuiaPuntoLlegada input {
  text-transform: uppercase !important;
}

.contentErroresValidacion{
  display: flex;
  flex-direction: column;
}

