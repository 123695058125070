#modalItemsMantenimiento {
    //z-index: 1050 !important;
}
.modal{
    z-index: 2050 !important;
}
.modal-backdrop{
    z-index: 2040 !important;
}
#menuNavegacion{
    //z-index: 2050 !important;
}
header{
    //z-index: 1030 !important;
}

#tablaItems *::before, *::after {
    box-sizing: border-box;
}

//.Mui-selected{ background-color: "#FFF000" }
//.MuiTableRow-hover{ background-color: "#FFF000"}