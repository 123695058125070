.btn-secondary {
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;
}

.appBar {
  top: auto !important;
  bottom: 0 !important;
}
