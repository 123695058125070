// @import "../../../../../scss/variables";
// @import "@coreui/coreui/scss/bootstrap";
// @import "@coreui/coreui/scss/root";



.detallesTableContainer {
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  max-height: 800px;
  overflow-x: visible;
  overflow-y: visible;
}

.autoCompleteItemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.autoCompleteItemDescripcion {
  width: 100%;
  font-size: small;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.autoCompleteItemDetails {
  display: flex;
  flex-direction: row;
}

.autoCompleteItemDetail {
  font-weight: lighter;
  margin: 5px;
  font-size: x-small;
}


.buttonsContainer{
  display: flex;

}