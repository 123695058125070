.data-grid .data-header>div {
  width: 100%;
}

.data-grid .data-header>div:after,
.data-grid .data-body .data-row:after {
  content: "";
  display: table;
  clear: both;
}

.data-grid .data-header>div>div {
  float: left;
  font-weight: bold;
  font-size: 12px;
  padding: 2px;
}

.data-grid .data-header>div>div.action-cell,
.data-grid .data-header>tr>.action-cell,
.data-grid .data-body .data-row>div.action-cell {
  width: 10%;
}

.data-grid .data-body .data-row>div {
  float: left;
  font-size: 12px;
}

.data-grid .data-body .data-row>.cell>input.data-editor {
  width: 100%;
  height: 100%;
}

.data-grid ul.data-body {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.cell {
  border: 1px !important;
  border-left-style: solid !important;
  border-right-style: solid !important;
  border-color: #3C4B64 !important;
  height: 25px !important;
  color: black !important;

}

.cell.read-only {
  background-color: transparent !important;

}

.customsheet-header {
  text-align: center !important;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #3C4B64 !important;
  color: #ffffff !important;
}

.customsheet-row-odd {
  background-color: #E4E7EA;
}

.customsheet-row-even > .cell > div > div > button,
.customsheet-row-odd > .cell > div > div > button{
  color: #ffffff !important;
  padding: 2px;
  margin: 2px;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
