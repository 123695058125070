#mainContent > div > div:nth-child(1) > div > div.col-12.col-md-4 > div > div > div > div:nth-child(2) > div > div > div.customFileUploader.dx-show-invalid-badge.dx-widget.dx-fileuploader > div > div > div > div.dx-fileuploader-input-wrapper > div.dx-fileuploader-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text{

}
#mainContent > div > div:nth-child(1) > div > div.col-12.col-md-4 > div > div > div > div:nth-child(2) > div > div > div.customFileUploader.dx-show-invalid-badge.dx-widget.dx-fileuploader > div > div > div > div.dx-fileuploader-input-wrapper > div.dx-fileuploader-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text{
    // position: absolute;
    font-size: inherit;
    // left: 80%;
    // top: 100%;
    // transform: translate(-100%, -100%);
    display: block;
}

#mainContent > div > div:nth-child(1) > div > div.col-12.col-md-4 > div > div > div > div:nth-child(2) > div > div > div > div.m-0.p-0.col-md-4 > div > div > div > div > div.dx-fileuploader-input-wrapper{
    display: grid;
}

#mainContent > div > div:nth-child(1) > div > div.col-12.col-md-4 > div > div > div > div:nth-child(2) > div > div > div > div.m-0.p-0.col-md-4 > div > div > div > div > div.dx-fileuploader-input-wrapper > div.dx-fileuploader-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text{
    width: auto;
    //left: -100% !important;
    margin-left: auto !important;
    padding: 0px !important;
    position: relative;
}