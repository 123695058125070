#commandsDetall
  > div.dx-buttongroup
  > div.dx-buttongroup-wrapper
  > div
  > div.dx-button-content {
  background-color: #39f !important;
}

#commandsDetall
  > div.dx-buttongroup
  > div.dx-buttongroup-wrapper
  > div
  > div.dx-button-content
  > i {
  color: white !important;
}
