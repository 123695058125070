.VisorXmlContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.VisorXmlLayoutContainer {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}


.VisorXmlLayoutToolbar {
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
}

.VisorXmlLayoutMain {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.VisorXmlToolbar {
  align-items: center;
  display: flex;
  width: 100%;
}

.VisorXmlToolbar-left {
  align-items: center;
  display: flex;
}

.VisorXmlToolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}

.VisorXmlToolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}
