.pdfVisualizerContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.pdfVisualizerLayoutContainer {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.pdfVisualizerLayoutToolbar {
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
}

.pdfVisualizerLayoutMain {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.pdfVisualizerToolbar {
  align-items: center;
  display: flex;
  width: 100%;
}

.pdfVisualizerToolbar-left {
  align-items: center;
  display: flex;
}

.pdfVisualizerToolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}

.pdfVisualizerToolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.react-pdf__Page__textContent,
.textLayer {
  position: absolute !important;
}

.react-pdf__Page__annotations,
.annotationLayer {
  height: 1px !important;
}
