.modal-invoice-change {
  z-index: 9999999 !important;

  .modal-dialog {
    z-index: 9999999 !important;
    position: relative;

    .modal-content {
      z-index: 9999999 !important;
      position: relative;
      background-color: #fff;
    }
  }
}

.modal-invoice-change + .modal-backdrop {
  z-index: 9999998 !important;
}

.textbox-total {
  .dx-texteditor-input {
    font-size: x-large !important;
    font-weight: 800 !important;
  }
}

.textbox-recibe {
  .dx-texteditor-input {
    font-size: x-large !important;
    font-weight: 800 !important;
    color: #ff0000 !important;
  }
}

.textbox-cambio {
  .dx-texteditor-input {
    font-size: x-large !important;
    font-weight: 800 !important;
    color: #16c600 !important;
  }
}

.textbox-total,
.textbox-recibe,
.textbox-cambio {
  .dx-texteditor-input {
    font-size: x-large !important;
    font-weight: 800 !important;
    text-align: right !important;
  }
}

.textbox-faltante {
  .dx-texteditor-input {
    text-align: right !important;
    font-size: x-large !important;
    font-weight: 800 !important;
    color: #ff0000 !important;
  }
}

.label-faltante {
  color: #ff0000 !important;
}

.label-ok {
  color: #321fdb !important;
}
