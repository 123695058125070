.loader-wrapper {
  //position: fixed;
  // width: 100%;
  // height: 100%;
  // text-align: center;
  // z-index: 1;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader {
  //position: relative;
  //top: 5px;
  //left: auto;
  // font-size: 20px;
  text-align: center;
  //display: inline-block;
  // width: 30px;
  // height: 30px;
  vertical-align: text-center;
}
.loader::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 5px;
  width: 35px;
  height: 35px;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.dropDownMenuGlobalMenu {
  z-index: 1;
}
