.popup-container-confirm-action {
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content-confirm-action {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup-message-confirm-action {
  margin-top: 10px;
}

.popup-buttons-confirm-action {
  margin-top: auto;
  margin-right: 10px;
  margin-bottom: 0;
  text-align: right;
  gap: 15px;
}

.popup-button-confirm-action {
  margin-left: 10px;
}

.popup-input-info-confirm-action {
  margin: 0px 25px;
}
