.alignTextRight{
    text-align: right;
}

.textDanger{
    color: #ff0000;
    border-color: #ff0000;;
}

.textSuccess{
    color: #0b333c;
    border-color: #0b333c;
}