.fieldset-anular {
  border: 1px solid #ccc; /* Borde del fieldset-anular */
  padding: 10px; /* Espaciado interno */
  border-radius: 8px; /* Bordes redondeados */
}

.fieldset-anular legend {
  font-size: 1em; /* Tamaño de la fuente del legend */
  font-weight: bold; /* Negrita */
  margin-bottom: 5px; /* Espaciado inferior */
}
